import { useLocalStorageState } from 'ahooks';
import { Button, Form, Image, Input, message } from 'antd';
import { isNil } from 'lodash-es';
import { TrpcBase } from '../../../utils/trpc';
import useUploadHook from '../edit/useUploadHook';
import dayjs from 'dayjs';
export default function Write() {
	const [form] = Form.useForm();
	const [stateParams, setStateParams] = useLocalStorageState<{
		roomNumber: string;
	}>('roomRecordCheck', {
		defaultValue: {
			roomNumber: '',
		},
	});
	const { data } = TrpcBase.apartment.getBuildingsConfig.useQuery();
	const id = data?.currentSelectedBuilding ?? '';
	const roomList = TrpcBase.apartment.roomList.useQuery(id, {
		enabled: id !== undefined,
	});

	const selectedRoom = roomList?.data?.find(
		(a) => a.roomNumber === form.getFieldValue('roomNumber'),
	);

	const { getDom, uploadFileAction } = useUploadHook({
		uploadFinishedCallback: async (mediaUrl) => {
			if (isNil(selectedRoom?.uuid)) {
				console.log('lock of selectedRoom?.uuid');
				return;
			}

			await recordMedia.mutate({
				roomUuid: selectedRoom?.uuid,
				mediaUrl: mediaUrl,
			});

			await refetch();
		},
	});

	const { data: record, refetch } = TrpcBase.apartment.checkRecord.useQuery(
		{ roomUuid: selectedRoom?.uuid ?? '' },
		{
			enabled: selectedRoom !== undefined,
		},
	);

	const recordMedia = TrpcBase.apartment.recordMedia.useMutation();
	const recordText = TrpcBase.apartment.recordText.useMutation();
	return (
		<div className="m-2">
			<div>走查记录</div>
			<Form
				form={form}
				initialValues={{
					...stateParams,
				}}
				onValuesChange={(v, all) => {
					setStateParams((pre) => {
						return {
							...pre,
							...all,
						};
					});
				}}
			>
				<Form.Item
					label="房间号"
					name="roomNumber"
					rules={[
						{
							type: 'enum',
							enum: roomList.data?.map((i) => i.roomNumber),
							validateTrigger: 'onChange',
						},

						// enum:roomList
					]}
				>
					<Input />
				</Form.Item>
				<div className="flex flex-col items-end gap-2 m-2">
					{record?.map((i) => {
						if (i.contentType === 'image') {
							return (
								<div className="flex flex-col items-end" key={i.uuid}>
									<div>
										{dayjs(i.recordingTime).format('YYYY年MM月DD日 HH:mm')}
									</div>
									<Image width={200} key={i.ossUrl} src={i.ossUrl} />
								</div>
							);
						}

						return (
							<div className="flex flex-col items-end" key={i.uuid}>
								<div>
									{dayjs(i.recordingTime).format('YYYY年MM月DD日 HH:mm')}
								</div>
								<div>{i.content}</div>
							</div>
						);
					})}
				</div>

				<Form.Item shouldUpdate>
					{(m) => {
						const roomNumber = m.getFieldValue('roomNumber');
						const roomNumberError = m.getFieldError('roomNumber');
						const isError = roomNumberError.length > 0;

						return (
							!isNil(roomNumber) &&
							!isError && (
								<div className="flex flex-row gap-2">
									{getDom('上传', `apartment-photo/${selectedRoom?.uuid}`)}

									<Form.Item name="text" noStyle>
										<Input />
									</Form.Item>

									<Button
										onClick={async () => {
											const roomNumber = form.getFieldValue('roomNumber');
											const text = form.getFieldValue('text');

											const opRes = await recordText.mutateAsync({
												roomUuid: selectedRoom?.uuid,
												text: text,
											});

											if (opRes) {
												message.success('success');
												form.setFieldValue('text', '');

												refetch();
											}
										}}
									>
										记录
									</Button>
								</div>
							)
						);
					}}
				</Form.Item>
			</Form>
		</div>
	);
}
